<template>
  <div class="pt-4 pl-4 pb-4 pr-4">
    <div v-if="unidades.length>0">
        <div>
          <h3>Cadastradores Locais do SIOP</h3>
          <em class="font-italic">Abaixo a relação de Unidades Cadastradoras e seus respectivos <a href="https://www1.siop.planejamento.gov.br/siopdoc/doku.php/glossario_siop#cadastrador_local">Cadastradores Locais</a>.</em>
          <br/>
          <br/>
        </div>
        <div class="input-group mb-3 col-12">
            <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"/></span>
            </div>
            <input type="text" v-model="filter" class="form-control" placeholder="Pesquisar unidade" aria-label="Pesquisar">
        </div>
        <div class="col-12" v-for="(unidade,index) of unidadesFiltradas" :key="index">
            <h4 class="font-weight-bold" v-if="index===0||unidade.poderId!==unidadesFiltradas[index-1].poderId">{{poderesMap.get(unidade.poderId).descricao}}</h4>
            <h5>{{unidade.id + ' - ' + unidade.nome}}{{unidade.sigla && unidade.sigla.trim()!=='' ? ' - ' + unidade.sigla : ''}}</h5>
            <h6>{{unidade.funcao?unidade.funcao:'Processo Orçamentário'}}</h6>
            <table class="table table-striped table-bordered table-hover">
               <thead>
                    <tr>
                        <th scope="col" width="45%">
                            <div class="flex-grow-1 text-left">Nome</div>
                        </th>
                        <th scope="col" width="20%">
                            <div class="flex-grow-1 text-left">Telefone</div>
                        </th>
                        <th scope="col" width="35%">
                            <div class="flex-grow-1 text-left">Email</div>
                        </th>
                    </tr>
               </thead>
               <tbody>
                <tr v-for="(cadastrador,i) of unidade.cadastradores" :key="i+'c'">
                    <td>{{cadastrador.nome}}</td>
                    <td>{{mascaraTelefone(cadastrador.telefone)}}</td>
                    <td><a :href="'mailto:'+cadastrador.email">{{cadastrador.email}}</a></td>
                </tr>
               </tbody>
            </table>
        </div>

    </div>
  </div>
</template>
<script>
import { gql } from '@apollo/client/core'
import bus from '../bus.js'

const query = gql`query cadastradoresPorUnidade {
    cadastradoresPorUnidade {
        id, nome, sigla, cadastradores{ nome, telefone, email }, poderId, funcao
    }
}`

const queryPoderes = gql`query poderes {
    poderes {
        id, descricao
    }
}`

export default {
  apollo: {
    unidades: {
      query,
      client: 'usuarios',
      update (data) {
        let unidades = []
        if (data.cadastradoresPorUnidade) {
          try {
            unidades = JSON.parse(JSON.stringify(data.cadastradoresPorUnidade))
            unidades = unidades.sort((a, b) => a.poderId - b.poderId)
            unidades.forEach((uc) => {
              uc.cadastradores = uc.cadastradores.sort((a, b) => a.nome.localeCompare(b.nome, 'pt-br'))
            })
          } catch (err) {
            console.log(data.cadastradoresPorUnidade)
            bus.emit('siop-limpa-tudo')
            bus.emit('siop-erro', `<strong>Falha ao carregar dados dos cadastradores locais.</strong> <p>[${err}]</p>`)
          }
        }
        return unidades
      },
      error (err) {
        let mensagem = ''
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          mensagem = err.graphQLErrors[0].message
        }
        console.log(err)
        bus.emit('siop-limpa-tudo')
        bus.emit('siop-erro', `<strong>Falha ao carregar dados dos cadastradores locais.</strong> <p>[${mensagem}]</p>`)
      }
    },
    poderes: {
      query: queryPoderes,
      client: 'usuarios',
      result ({ data }) {
        if (data?.poderes) {
          this.poderesMap = new Map(data.poderes.map(p => [p.id, p]))
        }
      },
      error (err) {
        let mensagem = ''
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          mensagem = err.graphQLErrors[0].message
        }
        console.log(err)
        bus.emit('siop-limpa-tudo')
        bus.emit('siop-erro', `<strong>Falha ao carregar dados dos cadastradores locais.</strong> <p>[${mensagem}]</p>`)
      }
    }
  },
  data: function () {
    return {
      unidades: [],
      poderes: [],
      poderesMap: new Map(),
      filter: ''
    }
  },
  computed: {
    unidadesFiltradas () {
      let unidades = this.unidades
      if (this.filter !== '' && this.unidades.length > 0) {
        const removeDiacritics = require('diacritics').remove
        const exp = new RegExp(removeDiacritics(this.filter.trim()), 'i')
        unidades = unidades.filter(e => {
          if (exp.test(removeDiacritics(e.nome)) || exp.test(e.sigla) || exp.test(e.id.toString())) {
            return e
          }
          return false
        })
      }
      return unidades
    }
  },
  methods: {
    mascaraTelefone (v) {
      if (v) {
        v = String(v)
        v = v.replace(/\D/g, '')
        v = v.replace(/^(\d{2})(\d)/g, '($1) $2')
        v = v.replace(/(\d)(\d{4})$/, '$1-$2')
      }
      return v
    }
  }
}
</script>
<style scoped src="@siop/standard-libui/dist/css/bootstrap-4/siop.css" />
