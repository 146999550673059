<template>
 <card
  titulo="Dados Abertos"
  classe="box-secao small margin-negative"
  cor="verde"
  texto="Acesse os dados no formato RDF e seu modelo ontológico."
  icon="dados-abertos"
  :url="urls.dados_abertos">
  </card>
</template>

<script>
import Card from '../Card.vue'
import UiConfig from '../../config/ui'
export default {
  components: {
    card: Card
  },
  data: function () {
    return {
      urls: UiConfig.urls
    }
  }
}
</script>
