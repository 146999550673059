<template>
  <div>
    <div id="barra-brasil" style="background:#7F7F7F; height: 20px; padding:0 0 0 10px;display:block;">
      <ul id="menu-barra-temp" style="list-style:none;">
        <li style="display:inline; float:left;padding-right:10px; margin-right:10px; border-right:1px solid #EDEDED">
            <a href="http://brasil.gov.br" style="font-family:sans,sans-serif; text-decoration:none; color:white;">Portal do Governo Brasileiro</a>
        </li>
      </ul>
    </div>
    <div v-if="this.ambiente.toLowerCase().indexOf('produção') === -1" class="siop_ambiente_treinamento">
        <p>{{ambiente}}</p>
    </div>
    <div class="topo">
      <div class="logo"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ambiente: {
      type: String,
      default () {
        return ''
      }
    }
  }
}
</script>
