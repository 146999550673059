<template>
  <div>
    <footer class="page-footer">
    </footer>
    <div id="footer-brasil"></div>
  </div>
</template>
<script>
import UiConfig from '../config/ui'
export default {
  data: function () {
    return {
      urls: UiConfig.urls
    }
  }
}
</script>
