<template>
    <section :class="classe" :id="id" @click='onClick'>
      <svg v-if="icon" :class="'icon-paginainicial pontas-arredondadas '+ icon">
        <use v-bind:xlink:href="'static/img/icones.svg#'+icon" xmlns:xlink="http://www.w3.org/1999/xlink" />
      </svg>
      <div class="">
        <h2 :class="cor">{{ titulo }}</h2>
        <p class="texto" v-if="texto">{{texto}}</p>
          <slot />
       </div>
    </section>
</template>

<script>
export default {
  props: ['id', 'titulo', 'classe', 'cor', 'texto', 'url', 'icon'],
  methods: {
    onClick () {
      if (this.url) {
        document.location = this.url
      }
    }
  }
}
</script>
