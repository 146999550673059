<template>
  <div class="container" id="noticia">
    <h2 class="azul">{{ $route.query.titulo }}</h2>
    <div v-html="$route.query.conteudo"></div>
  </div>
</template>
<style scoped>
div#noticia {
  margin-bottom: 3em;
}
</style>
