<template>
  <card
  titulo="Cadastro de Ações"
  classe="box-secao small margin-negative"
  cor="azul"
  texto="Veja dados financeiros e outras informações sobre as ações do orçamento federal"
  icon="acoes"
  :url="urls.cadastro_acoes">
  </card>
</template>

<script>
import Card from '../Card.vue'
import UiConfig from '../../config/ui'
export default {
  components: {
    card: Card
  },
  data: function () {
    return {
      urls: UiConfig.urls
    }
  }
}
</script>
