<template>
 <card
  titulo="Orçamento Cidadão Digital"
  classe="box-secao small height-fixed"
  cor="marrom"
  texto="Descubra quanto será gasto pelo governo federal nas diversas áreas, como: saúde, segurança, educação entre outras."
  icon="orcamento-cidadao"
  :url="urls.orcamento_cidadao">
  </card>
</template>

<script>
import Card from '../Card.vue'
import UiConfig from '../../config/ui'
export default {
  components: {
    card: Card
  },
  data: function () {
    return {
      urls: UiConfig.urls
    }
  }
}
</script>
