<template>
  <modal ref="modalMsg">
    <div class="modal-header">
      <div class="w-100 ">Autenticação SIOP</div>
      <div class="flex-shrink-1"><i class="fa fa-window-close-o text-white" role="button" aria-label="Close"
          @click="fecha()" /></div>
    </div>
    <div class="modal-body">
      <!-- form login -->
      <form v-show="isTelaLogin()" class="col-md-12" id="form_login" @submit.prevent="validar('form-login')"
        data-vv-scope="form-login">
        <div class="form-group row has-validation">
          <label for="login" class="col-sm-3 col-form-label">CPF:</label>
          <div class="col-sm-9">
            <masked-input id="login" name="login" type="text" v-model="login" placeholder="Informe seu CPF"
              mask="['ZZZZZZZZZZZ']" tokens="Z:[0-9]" :maxlength="14" :readonly="false" :disabled="false"
              class="form-control" :class="{ 'validacao-campo': formValidado && !validaCPF(login) }">
            </masked-input>
            <div class="texto validacao" v-show="formValidado && !validaCPF(login)">CPF Inválido</div>
          </div>
        </div>
        <div class="form-group row has-validation">
          <label class="col-sm-3 col-form-label" for="senha">Senha:</label>
          <div class="col-sm-9">
            <input type="password" id="senha" name="senha" placeholder="Informe sua senha" v-model="senha"
              class="form-control" autocomplete="current-password"
              :class="{ 'validacao-campo': formValidado && senha.length === 0 }">
              <div class="texto validacao" v-show="formValidado && senha.length === 0">Senha obrigatória</div>
          </div>
        </div>
        <div class="form-group row">
          <a class="senha col-sm-8" href="javascript:void(0)" @click="clicouEsqueciSenha"
            id="link_esqueceu_senha">Esqueceu sua
            senha?</a>
        </div>
        <button type="submit" class="btn btn-primary" id="loginpasswd" name="loginpasswd" style="display: none;"
         v-if="metodosAutenticacao.includes('siop')">Entrar</button>
      </form>
      <!-- form esqueci minha senha -->
      <form v-show="isTelaEsqueciSenha()" id="form_esqueci_senha" class="col-md-12" @submit.prevent="validar('form-esqueci-senha')"
        data-vv-scope="form-esqueci-senha">
        <p>Para solicitar uma nova senha, informe:</p>
        <div class="form-group row has-validation">
          <label for="cpf" class="col-sm-3 col-form-label">CPF:</label>
          <div class="col-sm-9">
            <masked-input id="cpf" name="cpf" type="text" v-model="login" placeholder="Informe seu CPF"
              mask="['ZZZZZZZZZZZ']" tokens="Z:[0-9]" :maxlength="14" :readonly="false" :disabled="false"
              class="form-control" :class="{ 'validacao-campo': formValidado && !validaCPF(login) }">
              </masked-input>
              <div class="texto validacao" v-if="formValidado && !validaCPF(login)">CPF inválido</div>
          </div>
        </div>
        <div class="form-group row has-validation">
         <label for="email" class="col-sm-3 col-form-label">E-mail:</label>
         <div class="col-sm-9">
          <input id="email" name="email" type="text" placeholder="Informe seu e-mail" v-model="email"
              class="form-control" :class="{ 'validacao-campo': formValidado && !validaEmail(email) }">
              <div class="texto validacao" v-if="formValidado && !validaEmail(email)">Informe um e-mail válido</div>
            </div>
        </div>
        <button type="submit" v-if="isTelaEsqueciSenha()" class="btn btn-primary" style="display: none;">Enviar</button>
      </form>
      <div v-show="isTelaSenhaEnviada()">
        <div class="alert alert-info" >
          <p>Um e-mail será enviado com a sua nova senha de acesso.</p>
        </div>
      </div>
      <div class="alert alert-info" v-show="isTelaTrocaSenhaDesativada()">
        <p>A recuperação de senha está desativada devido a uma instabilidade no serviço de e-mail.</p>
        <p>Pedimos a gentileza de solicitar uma nova senha através de nossa Central de Atendimento no telefone (61) 2020
          2121.</p>
      </div>
      <loading :isloading="loading"/>
    </div>
    <div class="modal-footer">
      <button type="button" v-if="isTelaEsqueciSenha()" class="btn btn-outline-primary" @click="cancelarEsqueciSenha">Cancelar</button>
      <button type="submit" v-if="isTelaEsqueciSenha()" class="btn btn-primary" @click="validar('form-esqueci-senha')">Enviar</button>
      <button type="button" v-if="isTelaLogin()" class="btn btn-outline-primary" @click="fecha">Fechar</button>
      <button type="submit" v-show="isTelaLogin()" class="btn btn-primary" id="loginpasswd" name="loginpasswd"
        @click="validar('form-login')" v-if="metodosAutenticacao.includes('siop')">Entrar</button>
      <button type="button" class="btn btn-primary" @click="voltar" v-show="isTelaSenhaEnviada()">Voltar</button>
    </div>
  </modal>

</template>
<script>
import UiConfig from '../../config/ui.js'
import bus from '../../bus.js'
import { jwtDecode } from 'jwt-decode'
import axios from 'axios'
import MaskedInput from '@siop/standard-libui/src/components/InputMask.vue'
import Loading from '@siop/standard-libui/src/components/Loading.vue'
import { gql } from '@apollo/client/core'
import { load } from 'recaptcha-v3'
import Modal from './Modal'

const queryMetodosAutenticacao = gql`query metodosAutenticacaoHabilitados {
    metodosAutenticacaoHabilitados
  }`
const queryChaveRecaptcha = gql`query chaveRecaptcha {
    chaveRecaptcha
  }`
const mutationRecuperarSenha = gql`mutation recuperarSenhaUsuario($cpf: String, $email: String!, $recaptcha: String) {
    recuperarSenhaUsuario(cpf: $cpf, email: $email, recaptcha: $recaptcha)
  }`

export default {
  components: {
    MaskedInput,
    Modal,
    Loading
  },
  data: function () {
    return {
      tela: UiConfig.tela.login.padrao,
      recaptchaLoaded: false,
      metodosAutenticacao: [],
      chaveRecaptcha: null,
      urls: UiConfig.urls,
      formValidado: false,
      login: '',
      senha: '',
      cpf: '',
      email: '',
      loading: false
    }
  },
  methods: {
    mostra () {
      this.$refs.modalMsg.mostra()
      this.tela = UiConfig.tela.login.padrao
    },
    fecha () {
      this.$refs.modalMsg.fecha()
    },
    isNumber (evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault()
      }
    },
    validar (escopo) {
      this.mensagemErro = ''
      if (this.isTelaLogin() && this.validaCPF(this.login) && this.senha.length > 0) {
        this.submitLogin()
        return
      }
      if (this.isTelaEsqueciSenha() && this.validaCPF(this.login) && this.validaEmail(this.email)) {
        this.submitEsqueciSenha()
        return
      }
      this.formValidado = true
    },
    validaCPF (cpf) {
      const validRegex = /\d{11}/
      return cpf.match(validRegex)
    },
    validaEmail (email) {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      return email.match(validRegex)
    },
    submitLogin () {
      bus.emit('siop-limpa-tudo')
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('usuario')
      const bcrypt = require('bcryptjs')
      const Md5 = require('md5.js')
      const salt = bcrypt.genSaltSync(11)
      const senhaMd5 = new Md5().update(this.senha).digest('hex')
      const senhaEncriptada = bcrypt.hashSync(senhaMd5, salt)
      const cpf = this.login.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')
      const usuario = { cpf, senha: senhaEncriptada }
      const app = this
      this.loading = true
      axios.post(UiConfig.urls.auth.login, usuario).then(
        // callback
        function (res) {
          app.fecha()
          app.loading = false
          window.localStorage.setItem('token', res.data.tokenSiop)
          const usuario = jwtDecode(window.localStorage.getItem('token'))
          if (usuario.trocarSenha) {
            bus.emit('siop-limpa-tudo')
            app.$router.push('trocarsenha')
          } else {
            document.location = UiConfig.paths.default
          }
        },
        // exception
        function (err) {
          app.fecha()
          app.loading = false
          let mensagemErro
          if (err) {
            if (err.response.status === 404) {
              mensagemErro = 'Serviço de autenticação não encontrado'
            } else if (err.response.status === 400 || err.response.status === 401) {
              mensagemErro = err.response.data.err
            } else {
              mensagemErro = 'Falha ao solicitar autenticação'
            }
          } else {
            mensagemErro = 'Falha ao solicitar autenticação'
          }
          bus.emit('siop-limpa-tudo')
          bus.emit('siop-erro', mensagemErro)
        })
    },
    async submitEsqueciSenha () {
      try {
        bus.emit('siop-limpa-tudo')
        this.loading = true
        if (!this.chaveRecaptcha) {
          bus.emit('siop-erro', 'Falha ao solicitar nova senha, chave recaptcha não disponível')
          this.fecha()
          return
        }
        load(this.chaveRecaptcha, { explicitRenderParameters: { badge: 'bottomleft' } }).then((recaptcha) => {
          recaptcha.execute('recuperarSenha').then((token) => {
            const cpf = this.login.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')
            this.$apollo.mutate({
              mutation: mutationRecuperarSenha,
              variables: { cpf, email: this.email, recaptcha: token },
              client: 'login'
            }).then((data) => {
              this.tela = UiConfig.tela.login.senhaEnviada
              bus.emit('siop-limpa-tudo')
              this.loading = false
              return true
            }).catch((error) => {
              console.log(JSON.stringify(error))
              if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
                bus.emit('siop-erro', 'Falha ao solicitar nova senha: ' + error.graphQLErrors[0].message)
              } else {
                bus.emit('siop-erro', 'Falha ao solicitar nova senha')
              }
              this.loading = false
              this.fecha()
              return false
            })
          }).catch((err) => {
            console.log(err)
            this.loading = false
            bus.emit('siop-erro', 'Falha ao solicitar nova senha, falha ao executar o recaptcha')
            this.fecha()
          })
        }).catch((err) => {
          console.log(err)
          this.loading = false
          bus.emit('siop-erro', 'Falha ao solicitar nova senha, falha ao carregar o recaptcha')
          this.fecha()
        })
      } catch (error) {
        console.log(error)
        this.loading = false
        bus.emit('siop-erro', 'Falha ao solicitar nova senha, recaptcha não carregado')
        this.fecha()
      }
    },
    isTelaLogin () {
      return this.tela === UiConfig.tela.login.padrao
    },
    isTelaEsqueciSenha () {
      return this.tela === UiConfig.tela.login.esqueciSenha
    },
    isTelaSenhaEnviada () {
      return this.tela === UiConfig.tela.login.senhaEnviada
    },
    isTelaTrocaSenhaDesativada () {
      return this.tela === UiConfig.tela.login.trocaSenhaDesativada
    },
    clicouEsqueciSenha () {
      this.formValidado = false
      this.cpf_senha = ''
      this.tela = UiConfig.tela.login.esqueciSenha
      bus.emit('siop-limpa-tudo')
    },
    cancelarEsqueciSenha () {
      this.tela = UiConfig.tela.login.padrao
      bus.emit('siop-limpa-tudo')
    },
    voltar () {
      this.tela = UiConfig.tela.login.padrao
    },
    loginNeoid () {
      const url = UiConfig.urls.auth.loginNeoid
      axios.get(url).then(
        // callback
        function (res) {
          document.location = res.data.URL
        },
        // exception
        function (err) {
          let mensagemErro
          if (err) {
            if (err.status === 404) {
              mensagemErro = 'Serviço de autenticação não encontrado'
            } else if (err.response.status === 400 || err.response.status === 401) {
              mensagemErro = err.response.data.err
            } else if (err.response.status === 500) {
              mensagemErro = 'Falha no serviço de autenticação'
            } else {
              mensagemErro = 'Falha ao solicitar autenticação'
            }
          } else {
            mensagemErro = 'Falha ao solicitar autenticação'
          }
          bus.emit('siop-limpa-tudo')
          bus.emit('siop-erro', mensagemErro)
        })
    },
    loginCertificado () {
      const url = UiConfig.urls.auth.loginCertificado
      axios.get(url).then(
        // callback
        function (res) {
          window.localStorage.setItem('token', res.data.tokenSiop)
          document.location = UiConfig.paths.default
        },
        // exception
        function (err) {
          let mensagemErro
          if (err) {
            if (err.response.status === 404) {
              mensagemErro = 'Serviço de autenticação não encontrado'
            } else if (err.response.status === 400 || err.response.status === 401) {
              mensagemErro = err.response.data.err
            } else if (err.response.status === 500) {
              mensagemErro = 'Falha no serviço de autenticação'
            } else {
              mensagemErro = 'Falha ao solicitar autenticação'
            }
          } else {
            mensagemErro = 'Falha ao solicitar autenticação'
          }
          bus.emit('siop-limpa-tudo')
          bus.emit('siop-erro', mensagemErro)
        })
    }
  },
  apollo: {
    chaveRecaptcha: {
      query: queryChaveRecaptcha,
      client: 'login',
      skip () { return this.recaptchaLoaded || this.tela !== UiConfig.tela.login.esqueciSenha },
      error (err) {
        console.log(err)
        this.erro = true
        bus.emit('siop-limpa-tudo')
        bus.emit('siop-erro', 'Falha ao inicializar recaptcha')
      }
    },
    metodosAutenticacao: {
      query: queryMetodosAutenticacao,
      client: 'login',
      update (data) {
        return data.metodosAutenticacaoHabilitados
      },
      error (err) {
        console.log(err)
        this.erro = true
        bus.emit('siop-limpa-tudo')
        bus.emit('siop-erro', `<strong>Falha na configuração de autenticação do SIOP.</strong> <p>[${err.graphQLErrors[0].message}]</p>`)
      }
    }
  },
  mounted () {
    const token = window.localStorage.getItem('token')
    let usuario = {}
    try {
      if (token != null && token !== '' && token !== 'null') {
        usuario = jwtDecode(token)
        if (usuario != null && usuario.trocarSenha) {
          this.$router.push('trocarsenha')
        }
      }
    } catch (error) {
      console.log('-->', error)
    }
  },
  destroyed () {
    clearInterval(this.interval)
  }
}
</script>
<style scoped src="@siop/standard-libui/dist/css/bootstrap-4/siop.css" />
