<template>
  <div id='webchat' />
</template>
<script>
import observer from '../observer.js'

export default {
  data: function () {
    return {
      urlProducao: 'https://sofia.siop.gov.br',
      urlTeste: 'https://chatbot-teste.app.sof.intra'
    }
  },
  props: {
    ambiente: {
      type: String,
      default () {
        return ''
      }
    }
  },
  mounted () {
    this.$webchat.init({
      selector: '#webchat',
      initPayload: '/apresentacao',
      socketUrl: this.url(),
      tooltipPayload: '/tooltip_registered_accounts',
      tooltipDelay: 40000,
      socketPath: this.socketPath(),
      customData: {
        language: 'pt'
      },
      title: 'Sofia - Assistente Virtual',
      subtitle: 'Online',
      inputTextFieldHint: 'Digite aqui a sua dúvida!',
      connectingText: 'Aguarde, conectando ao servidor...',
      profileAvatar: 'static/img/sofia-versao-2.png',
      showAvatar: true,
      hideWhenNotConnected: false,
      onSocketEvent: {
        bot_uttered: () => console.log('bot uttered')
      },
      showCloseButton: true,
      fullScreenMode: true,
      showFullScreenButton: true,
      connectOn: 'open',
      docViewer: false,
      params: {
        images: {
          dims: {
            width: 300,
            height: 200
          }
        },
        storage: 'session'
      }
    })
    this.adicionarPainelMsgPossoAjudar()
    this.exibirMsgPossoAjudar(true)
    setTimeout(() => { this.exibirMsgPossoAjudar(false) }, 10000)
    this.enviarMensagemAoApertarEnter()
  },
  methods: {
    exibirMsgPossoAjudar (exibir) {
      document.getElementById('div_msg_posso_ajudar').style.display = ''
      if (!document.getElementsByClassName('rw-launcher')[0].className.includes('rw-hide-sm') && !document.getElementsByClassName('rw-widget-container')[0].className.includes('rw-chat-open')) {
        document.getElementById('div_msg_posso_ajudar').className = (exibir ? 'rw-msg-posso-ajudar active' : 'rw-msg-posso-ajudar hidden')
      } else {
        document.getElementById('div_msg_posso_ajudar').className = 'rw-msg-posso-ajudar hidden'
      }
    },
    adicionarEventoBotaoAbrirChat () {
      const botao = document.getElementsByClassName('rw-launcher')[0]
      this.adicionarEvento(botao, 'mouseleave', false)
      this.adicionarEvento(botao, 'mouseenter', true)
      this.adicionarEvento(botao, 'click', false)
    },
    adicionarEvento (elemento, evento, exibir) {
      const app = this
      if (elemento.attachEvent) {
        elemento.attachEvent('on' + evento, function () { app.exibirMsgPossoAjudar(exibir) })
      } else {
        elemento.addEventListener(evento, function () { app.exibirMsgPossoAjudar(exibir) }, false)
      }
    },
    adicionarPainelMsgPossoAjudar () {
      const divMsgPossoAjudar = document.createElement('div')
      divMsgPossoAjudar.id = 'div_msg_posso_ajudar'
      divMsgPossoAjudar.className = 'rw-msg-posso-ajudar'
      divMsgPossoAjudar.style.display = 'none'

      const img = document.createElement('img')
      img.src = 'static/img/sofia-versao-2.png'
      img.alt = 'Sofia'
      img.style.width = '46px'
      divMsgPossoAjudar.appendChild(img)

      const paragrafo = document.createElement('p')
      paragrafo.className = 'texto'
      paragrafo.style.display = 'block'
      paragrafo.innerHTML = 'Olá! Eu sou a Sofia. <br> Posso te ajudar?'
      divMsgPossoAjudar.appendChild(paragrafo)

      const botao = document.getElementsByClassName('rw-launcher')[0]
      botao.parentNode.insertBefore(divMsgPossoAjudar, botao)

      this.adicionarEventoBotaoAbrirChat()
    },
    enviarMensagemAoApertarEnter () {
      observer.observe(document.getElementById('webchat'), {
        subtree: true,
        childList: true
      })
    },
    url () {
      return this.ambiente.toLowerCase().indexOf('produção') !== -1 ? this.urlProducao : this.urlTeste
    },
    socketPath () {
      return '/socket.io/'
    }

  }
}
</script>
<style>
  .rw-conversation-container .rw-title {
    font-size: 17px !important;
  }

  .rw-conversation-container .rw-header.rw-with-subtitle span.rw-with-avatar {
    font-size: 12px !important;
    bottom: 15px !important;
    color: #FFF !important;
  }

  .rw-conversation-container .rw-header.rw-with-subtitle .rw-title {
    top: 18px !important;
  }

  .rw-conversation-container .rw-message-text {
    font-size: 14px !important;
    color: #fff !important;
  }
  .rw-conversation-container .rw-client {
    border-radius: 15px 15px 0 15px !important;
    background-color: #0075BE !important;
  }
  .rw-conversation-container .rw-header {
    background-color: #0075BE !important;
  }

  .rw-conversation-container .rw-header.rw-with-subtitle .rw-title.rw-with-avatar {
    color: #FFFFFF !important;
  }

  .rw-conversation-container .rw-toggle-fullscreen-button {
    background-color: #0075BE !important;
  }

  .rw-launcher {
    background-color: #0075BE !important;
  }

  .rw-conversation-container .rw-response {
    background-color: #e9e7e7 !important;
    color: #4f4f4f !important;
  }

  .rw-conversation-container .rw-response .rw-message-text {
    color: #4f4f4f !important;
  }
  .rw-conversation-container .rw-response {
    max-width: 77% !important;
  }

  .rw-widget-container {
    margin: 0 10px 20px 0 !important;
  }

  .rw-msg-posso-ajudar {
    overflow: hidden;
    position: absolute;
    right: 30px;
    background: #0075BE;
    width: 240px;
    height: 60px;
    padding: 9px 35px 11px 10px;
    border-radius: 10px 0 0 10px;

    text-align: center;
    display: flex;
  }

  .rw-msg-posso-ajudar p {
    margin-block-start: 5px !important;
    margin-block-end: 0 !important;
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
    font-size: 14px !important;
    padding-left: 17px;
    font-family: Inter,sans-serif;
    color: #FFF;
    font-weight: bold;
  }

  .rw-msg-posso-ajudar.active {
    animation-duration: 1s;
    animation-name: slidein;
    animation-fill-mode: forwards;
  }

  .rw-msg-posso-ajudar.hidden {
    animation-duration: 2s;
    animation-name: slideout;
    animation-fill-mode: forwards;
  }

  .rw-msg-posso-ajudar.active p {
    animation-duration: 2.5s;
    animation-name: fadein;
    animation-fill-mode: forwards;
  }

  .rw-msg-posso-ajudar.hidden p{
    animation-duration: 0.4s;
    animation-name: fadeout;
    animation-fill-mode: forwards;
  }

  .rw-conversation-container .rw-new-message {
    font-size: 14px !important;
    height: 75px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    resize: none;
    border-radius: 10px;
    margin: 2.5px 5px;
  }

  .rw-messages-container {
    max-height: 55vh !important;
  }

  .rw-conversation-container .rw-sender {
    height: auto !important;
  }

  .rw-conversation-container .rw-sender,
  .rw-conversation-container .rw-send {
      background-color: #d9d9d9 !important;
  }

  .rw-widget-container{
    z-index: 999999999 !important;
  }

  @keyframes slidein {
    from {
    width: 0px;
    }

    to {
    width: 240px;
    }
  }

  @keyframes slideout {
    from {
    width: 240px;
    visibility: visible;
    }

    to {
    width: 0px;
    visibility:hidden;
    }
  }

  @keyframes fadeout {
    from {
    opacity:1;
    transform: scale3d(1, 1, 1);
    visibility: visible;
    }

    to {
    opacity:0;
    visibility:hidden;
    transition:1s;
    }
  }

  @keyframes fadein {
    from {
    opacity:0;
    visibility:hidden;
    }

    20% {
    opacity:0;
    visibility:hidden;
    }

    to {
    opacity:1;
    transform: scale3d(1, 1, 1);
    visibility: visible;
    }
  }
</style>
