<template>
  <div class="container">
      <div class="alert alert-info" role="alert">
        <span>Comunicando com gov.br para validar a autenticação.</span>
      </div>
    <div id="carregando" class="loader"></div>
  </div>
</template>
<script>
import UiConfig from '../../config/ui'
import bus from '../../bus.js'
import axios from 'axios'

const href = window.location.href
if (href.indexOf('loginunicocallback') > -1 && href.indexOf('#') > href.indexOf('?')) {
  location.replace(getUrl('/loginunicocallback'))
}

function getUrl (path) {
  const href = window.location.href
  const hashPos = href.indexOf('#')
  let base = hashPos > -1 ? href.slice(0, hashPos) : href

  const searchPos = base.indexOf('?')
  const query = searchPos > -1 ? base.slice(searchPos) : ''
  base = query ? base.slice(0, searchPos) : base

  return `${base}#${path + query}`
}
export default {
  mounted () {
    if (this.$route.query.error !== undefined && this.$route.query.error === 'access_denied') {
      bus.emit('siop-limpa-tudo')
      bus.emit('siop-erro', 'Uso da autenticação gov.br não autorizada pelo usuário.')
      this.$router.push('paginainicial')
    } else {
      const href = window.location.href
      if (href.indexOf('loginunicocallback') > -1 && href.indexOf('#') > href.indexOf('?')) {
        location.replace(getUrl('/loginunicocallback'))
        return
      }
      if (this.$route.query.code !== undefined && this.$route.query.state !== undefined) {
        this.encaminhaRequisicaoLoginServico()
      } else {
        bus.emit('siop-limpa-tudo')
        bus.emit('siop-erro', 'Falha ao solicitar autenticação com gov.br')
        this.$router.push('paginainicial')
      }
    }
  },
  methods: {
    encaminhaRequisicaoLoginServico () {
      const app = this
      const url = UiConfig.urls.auth.loginUnicoCallback + '?code=' + app.$route.query.code + '&state=' + app.$route.query.state
      axios.get(url).then(
        // callback
        function (res) {
          window.localStorage.setItem('token', res.data.tokenSiop)
          document.location = UiConfig.paths.default
        },
        // exception
        function (err) {
          let mensagemErro = ''
          if (err) {
            if (err.response.status === 404) {
              mensagemErro = 'Serviço de autenticação não encontrado'
            } else if (err.response.status === 400 || err.response.status === 401) {
              mensagemErro = err.response.data.err
            } else {
              mensagemErro = 'Falha ao solicitar autenticação com gov.br'
            }
          } else {
            mensagemErro = 'Falha ao solicitar autenticação com gov.br'
          }
          bus.emit('siop-limpa-tudo')
          bus.emit('siop-erro', mensagemErro)
          app.$router.push('paginainicial')
        }
      )
    }
  }
}
</script>
<style scoped>
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: 75px 0 0 -75px;
  border: 10px solid #3498db;
  border-radius: 50%;
  border-top: 10px solid #B2D2FF;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 0.75s linear infinite;
  animation: spin 0.75s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
