<template>
  <div class="container">
    <card titulo="Acesso ao sistema" classe="box login meio">
      <form id="form_trocar_senha" @submit.prevent="submitTrocarSenha">
        <div class="row">
            <span class="col-md-12 m-2 p-1">Cadastre uma nova senha do SIOP</span>
            <span class="col-md-12"><br></span>
            <div class="form-group row col-md-12">
                <label class="col-md-4" for="nova_senha">Nova Senha:</label>
                <div class="input-group col-md-8">
                    <input class="form-control" id="nova_senha" name="nova_senha" :type="novaSenhaShow ? 'text' : 'password'"
                        placeholder="Informe nova senha" maxlength="64" autocomplete="new-password" required
                        v-model="nova_senha" ref="nova_senha">
                    <div class="input-group-append">
                        <span class="input-group-text"  @click="novaSenhaShow = !novaSenhaShow">
                            <i class="fa" id="visible" :class="!novaSenhaShow ? 'fa-eye' : 'fa-eye-slash'"/>
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group row col-md-12">
                <label class="col-md-4" for="confirmacao_senha">Confirmação:</label>
                <div class="input-group col-md-8">
                    <input class="form-control" id="confirmacao_senha" name="confirmacao_senha" :type="confirmacaoNovaSenhaShow ? 'text' : 'password'"
                      placeholder="Confirme nova senha" maxlength="64" autocomplete="new-password" required
                      v-model="confirmacao_senha">

                    <div class="input-group-append">
                        <span class="input-group-text" @click="confirmacaoNovaSenhaShow = !confirmacaoNovaSenhaShow" >
                            <i class="fa" id="visible" :class="!confirmacaoNovaSenhaShow ? 'fa-eye' : 'fa-eye-slash'"  />
                        </span>
                    </div>
                </div>
                <span v-if="nova_senha&&confirmacao_senha&&nova_senha!==confirmacao_senha" class="text-danger col-md-8 offset-md-4">A confirmação deve ser igual a nova senha</span>
            </div>
            <div class="col-md-8 offset-md-4">
                <span>Sua senha deve ter pelo menos:</span>
                <ul>
                    <li v-for="validacao of validacoesSenha" :key="validacao.label">
                        <i class="fa" :class="validacao.test.test(nova_senha.trim()) ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"/>
                        {{validacao.label}}
                    </li>
                </ul>
            </div>
            <p class="col-md-12 text-danger text-center m-2">Tempo restante para alterar a senha：{{ remaingTimeExp }}</p>
            <div class="col-md-12">
                <button type="submit" class="btn btn-primary">Enviar</button>&nbsp;
                <button type="button" class="btn btn-outline-info" @click="cancelarTrocarSenha">Cancelar</button>
            </div>
        </div>
      </form>
    </card>
  </div>
</template>
<script>
import Card from './../Card.vue'
import UiConfig from '../../config/ui'
import bus from '../../bus.js'
import { jwtDecode } from 'jwt-decode'
import axios from 'axios'

export default {
  components: {
    card: Card
  },
  data: function () {
    return {
      login: '',
      senha: '',
      cpf: '',
      email: '',
      nova_senha: '',
      confirmacao_senha: '',
      urls: UiConfig.urls,
      trocarSenhaTokeExp: 300,
      remaingTimeExp: '',
      interval: null,
      novaSenhaShow: false,
      confirmacaoNovaSenhaShow: false,
      validacoesSenha: [
        { label: '8 caracteres', test: /^.{8,}/ },
        { label: '1 letra minúscula', test: /^(?=.*[a-z])/ },
        { label: '1 letra maiúscula', test: /^(?=.*[A-Z])/ },
        { label: '1 número', test: /^(?=.*[0-9])/ },
        { label: '1 caractere especial (!@#$%^&*)', test: /^(?=.*[!@#$%^&*])/ }
      ]
    }
  },
  methods: {
    updateRemaingTime () {
      const remainingTotalSeconds = this.trocarSenhaTokeExp - (Date.now() / 1000)
      const remainingMinutes = Math.floor(remainingTotalSeconds / 60)
      const remainingSeconds = Math.floor(remainingTotalSeconds % 60)
      this.remaingTimeExp = remainingMinutes + ':' + (remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds)
      if (remainingTotalSeconds <= 0) {
        this.remaingTimeExp = '0:00'
        clearInterval(this.interval)
      }
    },
    submitTrocarSenha () {
      if (!this.nova_senha || !this.confirmacao_senha || this.nova_senha !== this.confirmacao_senha) {
        return
      }
      for (const validacao of this.validacoesSenha) {
        if (!validacao.test.test(this.nova_senha)) {
          return
        }
      }
      const usuario = { novasenha: this.nova_senha }
      const app = this
      axios.post(UiConfig.urls.auth.trocarSenha, usuario, { headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token') } }).then(
        // callback
        function (res) {
          if (res.status === 200) {
            const usuarioToken = jwtDecode(window.localStorage.getItem('token'))
            app.login = usuarioToken.cpf
            app.senha = app.nova_senha
            bus.emit('siop-limpa-tudo')
            app.submitLogin()
          } else {
            console.log(res.data)
          }
        },
        // exception
        function (err) {
          let mensagemErro
          if (err) {
            if (err.response.status === 404) {
              mensagemErro = 'Serviço de autenticação não encontrado'
            } else if (err.response.status === 400 || err.response.status === 401) {
              if (err.response.data.err === 'JWT is expired') {
                mensagemErro = 'Tempo limite para mudança de senha ultrapassado'
              } else {
                mensagemErro = err.response.data.err
              }
            } else {
              mensagemErro = 'Falha ao trocar senha'
            }
          } else {
            mensagemErro = 'Falha ao trocar senha'
            console.log(err)
          }
          bus.emit('siop-limpa-tudo')
          bus.emit('siop-erro', mensagemErro)
        })
    },
    submitLogin () {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('usuario')
      const bcrypt = require('bcryptjs')
      const Md5 = require('md5.js')
      const salt = bcrypt.genSaltSync(11)
      const senhaMd5 = new Md5().update(this.senha).digest('hex')
      const senhaEncriptada = bcrypt.hashSync(senhaMd5, salt)
      const usuario = { cpf: this.login, senha: senhaEncriptada }
      this.validandoLogin = true
      const app = this
      axios.post(UiConfig.urls.auth.login, usuario).then(
        // callback
        function (res) {
          app.validandoLogin = false
          window.localStorage.setItem('token', res.data.tokenSiop)
          const usuario = jwtDecode(window.localStorage.getItem('token'))
          if (usuario.trocarSenha) {
            app.nova_senha = ''
            app.confirmacao_senha = ''
            app.trocarSenhaTokeExp = usuario.exp
            app.interval = setInterval(() => { app.updateRemaingTime() }, 1000)
            app.updateRemaingTime()
            bus.emit('siop-limpa-tudo')
          } else {
            document.location = UiConfig.paths.default
          }
        },
        // exception
        function (err) {
          app.validandoLogin = false
          let mensagemErro
          if (err) {
            if (err.response.status === 404) {
              mensagemErro = 'Serviço de autenticação não encontrado'
            } else if (err.response.status === 400 || err.response.status === 401) {
              mensagemErro = err.response.data.err
            } else {
              mensagemErro = 'Falha ao solicitar autenticação'
            }
          } else {
            mensagemErro = 'Falha ao solicitar autenticação'
          }
          bus.emit('siop-limpa-tudo')
          bus.emit('siop-erro', mensagemErro)
        })
    },
    cancelarTrocarSenha () {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('usuario')
      this.$router.push('paginainicial')
      bus.emit('siop-limpa-tudo')
    }
  },
  mounted () {
    const token = window.localStorage.getItem('token')
    let usuario = {}
    try {
      if (token != null && token !== '' && token !== 'null') {
        usuario = jwtDecode(token)
        if (!usuario) {
          this.$router.push('paginainicial')
        } else if (usuario != null && usuario.trocarSenha) {
          this.trocarSenhaTokeExp = usuario.exp
          this.interval = setInterval(() => { this.updateRemaingTime() }, 1000)
          this.updateRemaingTime()
        } else {
          document.location = UiConfig.paths.default
        }
      } else {
        this.$router.push('paginainicial')
      }
    } catch (error) {
      console.log('-->', error)
    }
  },
  destroyed () {
    clearInterval(this.interval)
  }
}
</script>
<style scoped src="@siop/standard-libui/dist/css/bootstrap-4/siop.css" />
