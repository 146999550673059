<script>
import UiConfig from '../../config/ui'
import bus from '../../bus.js'
import axios from 'axios'
export default {
  created () {
    const url = UiConfig.urls.auth.nuvemcallback + '?code=' + this.$route.query.code + '&state=' + this.$route.query.state
    axios.get(url).then(
      // callback
      function (res) {
        window.localStorage.setItem('token', res.data.tokenSiop)
        document.location = UiConfig.paths.default
      },
      // exception
      function (err) {
        if (err) {
          if (err.response.status === 404) {
            this.mensagemErro = 'Serviço de autenticação não encontrado'
          } else if (err.response.status === 400 || err.response.status === 401) {
            this.mensagemErro = err.response.data.err
          } else {
            this.mensagemErro = 'Falha ao solicitar autenticação'
          }
        } else {
          this.mensagemErro = 'Falha ao solicitar autenticação'
        }
        bus.emit('siop-limpa-tudo')
        bus.emit('siop-erro', this.mensagemErro)
        this.$router.push('paginainicial')
      })
  }
}
</script>
