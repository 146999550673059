<template>
  <div id="app" class="container-fluid">
    <header>
      <login-header v-if="!somiolo" :ambiente="ambiente"/>
      <siop-messages></siop-messages>
    </header>
    <main>
      <webchat v-if="isExibirWebchat() && !somiolo" :ambiente="ambiente" />
      <router-view></router-view>
    </main>
    <footer v-if="!somiolo">
      <login-footer v-if="!somiolo" />
    </footer>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Webchat from './components/Webchat.vue'
import Messages from './components/Messages.vue'
import axios from 'axios'

const queryAmbiente = `query ambiente(){
  ambiente()
}`

export default {
  name: 'app',
  components: {
    'login-header': Header,
    'login-footer': Footer,
    'siop-messages': Messages,
    webchat: Webchat
  },
  data: function () {
    return {
      ambiente: ''
    }
  },
  created () {
    axios.post('/modulo/login/api', JSON.stringify({ query: queryAmbiente }))
      .then(response => {
        if (response.data && response.data.data && response.data.data.ambiente) {
          this.ambiente = response.data.data.ambiente
          window.localStorage.setItem('ambiente', this.ambiente)
        }
      })
      .catch(error => {
        if (error.response.data.errors) {
          console.log(JSON.stringify(error.response.data.errors))
        }
      })
  },
  computed: {
    somiolo: function () {
      return this.$route.query.somiolo !== undefined
    }
  },
  methods: {
    isExibirWebchat () {
      return (this.ambiente.toLowerCase().indexOf('teste') !== -1 || this.ambiente.toLowerCase().indexOf('diário') !== -1 || this.ambiente.toLowerCase().indexOf('produção') !== -1)
    }
  }
}
</script>
