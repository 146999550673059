<template>
  <div class="container">
    <card-login/>
    <div class="box-meio">
      <card-botao-siop />
      <card-botao-painel-orcamento />
      <card-botao-orcamento-cidadao />
      <card-botao-manuais />
      <card-botao-cadastro-acoes />
      <card-botao-dados-abertos />
    </div>
    <card-noticias />
  </div>
</template>

<script>
import Login from '../paginainicial/Login.vue'
import BotaoSiop from '../paginainicial/BotaoSiop.vue'
import BotaoManuais from '../paginainicial/BotaoManuais.vue'
import BotaoPainelOrcamento from '../paginainicial/BotaoPainelOrcamento.vue'
import BotaoOrcamentoCidadao from '../paginainicial/BotaoOrcamentoCidadao.vue'
import BotaoCadastroAcoes from '../paginainicial/BotaoCadastroAcoes.vue'
import BotaoDadosAbertos from '../paginainicial/BotaoDadosAbertos.vue'
import Noticias from '../paginainicial/Noticias.vue'

export default {
  name: 'PaginaInicial',
  components: {
    'card-login': Login,
    'card-botao-siop': BotaoSiop,
    'card-botao-manuais': BotaoManuais,
    'card-botao-painel-orcamento': BotaoPainelOrcamento,
    'card-botao-orcamento-cidadao': BotaoOrcamentoCidadao,
    'card-botao-cadastro-acoes': BotaoCadastroAcoes,
    'card-botao-dados-abertos': BotaoDadosAbertos,
    'card-noticias': Noticias
  }
}
</script>
