const observer = new MutationObserver(function (mutations) {
  mutations.forEach(function (mutation) {
    // let textarea = document.getElementById('rw-new-message-enter')
    const textarea = document.getElementsByClassName('rw-new-message')[0]
    const botaoEnvio = document.getElementsByClassName('rw-send')[0]
    if (typeof textarea !== 'undefined') {
      if (textarea.className !== 'rw-new-message keyup-enter-submit') {
        textarea.addEventListener('keyup', function (event) {
          if (event.keyCode === 13) {
            event.preventDefault()
            botaoEnvio.click()
          }
        })
        textarea.classList.add('keyup-enter-submit')
      }
    }
  })
})
export default observer
