<template>
 <card
  titulo="Manuais"
  classe="box-secao small-fixed"
  cor="laranja"
  texto="Saiba como usar o SIOP com o passo a passo de cada módulo."
  icon="manuais"
  :url="urls.manuais">
  </card>
</template>

<script>
import Card from '../Card.vue'
import UiConfig from '../../config/ui'
export default {
  components: {
    card: Card
  },
  data: function () {
    return {
      urls: UiConfig.urls
    }
  }
}
</script>
