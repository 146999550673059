<template>
  <div :class=" { 'modal-open': show }">
    <div class="modal fade" tabindex="-1" :class="{ show: show }" :style="modalStyle">
      <div class="modal-dialog modal-dialog-centered" :class="[size, { 'modal-dialog-scrollable': scrollable }]">
        <div class="modal-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * O Modal é usado para mostrar formulários para inserção de dados, como tela de
 * confirmação antes de realizar operação irreversível como exclusão de registros.
 */
export default {
  name: 'Modal',
  status: 'review',
  release: '-',
  props: {
    size: { type: String, default: 'modal-lg' },
    scrollable: { type: Boolean, default: true }
  },
  data: () => ({ show: false, overflowY: null }),
  computed: {
    modalStyle () {
      return this.show ? 'display: block;' : {}
    }
  },
  methods: {
    mostra () {
      if (this.overflowY == null) {
        this.overflowY = document.body.style.overflowY
        document.body.style.overflowY = 'hidden'
      }

      this.show = true
    },
    fecha () {
      document.body.style.overflowY = this.overflowY
      this.overflowY = null

      this.show = false
    }
  }
}
</script>
<docs>
  ```jsx
  <div>
    <modal ref="modalConfirmacaoRetorno">
      <div class="modal-header">
        <span>Você tem certeza que deseja retornar o(s) registro(s) ?</span>
      </div>
      <div class="modal-body">
        <p class="mt-4" >Após o retorno, todos os valores que você inseriu serão apagados. E o registro será liberado para edição na fase anterior.</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" @click="$refs.modalConfirmacaoRetorno.fecha()">Retornar</button>
        <button class="btn btn-secondary" @click="$refs.modalConfirmacaoRetorno.fecha()">Fechar</button>
      </div>
    </modal>
     <button class="btn btn-primary" @click="$refs.modalConfirmacaoRetorno.mostra()">Abrir Modal de Confirmação</button>
    <button class="btn btn-primary" @click="$refs.modalFormulario.mostra()">Abrir Modal Formulário</button>
     <modal ref="modalFormulario">
      <div class="modal-header">
        <span>Novo Registro</span>
      </div>
      <div class="modal-body">
        <div class="formulario">
            <div class="form-group">
              <label>Nome</label>
              <div class="input-group mb-3">
                <input type="text" style="form-control" />
              </div>
            </div>
            <div class="form-group">
              <label>Sobrenome</label>
              <div class="input-group mb-3">
                <input type="text" style="form-control" />
              </div>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" @click="$refs.modalFormulario.fecha()">Salvar</button>
        <button class="btn btn-secondary" @click="$refs.modalFormulario.fecha()">Fechar</button>
      </div>
    </modal>

  </div>
  ```
</docs>
<style scoped src="@siop/standard-libui/dist/css/bootstrap-4/siop.css" />
