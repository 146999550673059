<template>
  <card titulo="Atendimento SIOP" classe="box small-fixed fundo" cor="cinza">
    <ul class="horizontal">
      <li class="primeiro" ><a :href="urls.portal_servicos">Portal de Atendimento</a></li>
      <li class="terceiro"><a :href="urls.solicitar_acesso">Solicitação de acesso</a></li>
      <li v-if="!isExibirWebchat()"></li>
      <li class="quarto" v-if="isExibirWebchat()"><a href="#" v-on:click="openChat()">Chat - Sofia</a></li>
    </ul>
  </card>
</template>

<script>
import Card from '../Card.vue'
import UiConfig from '../../config/ui'
import axios from 'axios'

const queryAmbiente = `query ambiente(){
  ambiente()
}`

export default {
  components: {
    card: Card
  },
  data: function () {
    return {
      urls: UiConfig.urls,
      ambiente: ''
    }
  },
  created () {
    axios.post('/modulo/login/api', JSON.stringify({ query: queryAmbiente }))
      .then(response => {
        if (response.data && response.data.data && response.data.data.ambiente) {
          this.ambiente = response.data.data.ambiente
        }
      })
      .catch(error => {
        if (error.response.data.errors) {
          console.log(JSON.stringify(error.response.data.errors))
        }
      })
  },
  methods: {
    isExibirWebchat () {
      return (this.ambiente.toLowerCase().indexOf('teste') !== -1 || this.ambiente.toLowerCase().indexOf('diário') !== -1 || this.ambiente.toLowerCase().indexOf('produção') !== -1)
    },
    openChat () {
      const botoes = document.getElementsByClassName('rw-open-launcher')
      if (botoes) {
        botoes[0].click()
      }
    }
  }
}
</script>
<style>
</style>
