import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'
import { v4 as uuidv4 } from 'uuid'

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'X-Request-Id': uuidv4(),
      authorization: localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : null
    }
  })
  return forward(operation)
})

function createClientService (params) {
  const cache = new InMemoryCache()
  const uri = params
  const link = createHttpLink({
    // You should use an absolute URL here
    uri
  })
  const mLink = middlewareLink.concat(link)
  const service = new ApolloClient({
    link: mLink,
    cache
  })
  return service
}

const loginService = createClientService('/modulo/login/api')
console.log('apollo iniciado')
export const apolloProvider = createApolloProvider({
  defaultClient: loginService,
  clients: {
    login: loginService,
    comunicacao: createClientService('/modulo/comunicacao/api'),
    usuarios: createClientService('/modulo/usuarios/api')
  }
})
