<template>
<card
  titulo="Painel do Orçamento"
  classe="box-secao small height-fixed"
  cor="verde"
  texto="Consulte informações sobre o orçamento: despesas de TI, série histórica de execução, emendas individuais e muito mais."
  icon="portal-orcamento"
  :url="urls.painel_orcamento">
  </card>
</template>

<script>
import Card from '../Card.vue'
import UiConfig from '../../config/ui'
export default {
  components: {
    card: Card
  },
  data: function () {
    return {
      urls: UiConfig.urls
    }
  }
}
</script>
