<template>
  <div class="container">
    <div v-if="error" class="alert alert-danger" role="alert">
      <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" @click="error=''">
        <span aria-hidden="true">&times;</span>
      </button>
      <span v-html="error"></span>
    </div>
    <div v-if="alert" class="alert alert-warning" role="alert">
      <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" @click="alert=''">
        <span aria-hidden="true">&times;</span>
      </button>
      <span v-html="alert"></span>
    </div>
    <div v-if="info" class="alert alert-info" role="alert">
      <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" @click="info=''">
        <span aria-hidden="true">&times;</span>
      </button>
      <span v-html="info"></span>
    </div>
    <div v-if="success" class="alert alert-success" role="alert">
      <button type="button" class="close" data-dismiss="alert" aria-label="Fechar" @click="success=''">
        <span aria-hidden="true">&times;</span>
      </button>
      <span v-html="success"></span>
    </div>
  </div>
</template>

<script>
import bus from '../bus.js'

export default {
  data: () => {
    return { error: '', alert: '', info: '', success: '' }
  },
  mounted () {
    bus.on('siop-erro', (msg) => { this.error = msg })
    bus.on('siop-alerta', (msg) => { this.alert = msg })
    bus.on('siop-info', (msg) => { this.info = msg })
    bus.on('siop-sucesso', (msg) => { this.success = msg })
    bus.on('siop-limpa-tudo', () => {
      this.error = ''
      this.alert = ''
      this.info = ''
      this.success = ''
    })
  }
}
</script>
<style scoped>
.alert {
  position:sticky;
  top:0;
  z-index: 999;
  width: 100%;
  margin-top: 0;
}
</style>
