import { createApp } from 'vue'
import { createWebHashHistory, createRouter } from 'vue-router'
import App from './App'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/css/v4-shims.min.css'
import PaginaInicial from './components/paginainicial/PaginaInicial'
import Noticia from './components/paginainicial/Noticia'
import NuvemCallback from './components/paginainicial/NuvemCallback'
import LoginUnicoCallback from './components/paginainicial/LoginUnicoCallback'
import TrocarSenha from './components/paginainicial/TrocarSenha'
import AutenticacaoSIOP from './components/paginainicial/AutenticacaoSIOP'
import Cadastradores from './components/Cadastradores.vue'
import CadastradoresSof from './components/CadastradoresSof.vue'
import webchat from '../public/static/js/webchat-v0.9.0.js'
import { apolloProvider } from './apollo.js'
import { jwtDecode } from 'jwt-decode'
import './css/govbr.css'
import './css/reset.css'
import './css/style.css'

const token = window.localStorage.getItem('token')
let usuario = {}
let autenticado = false
try {
  if (token != null && token !== '' && token !== 'null') {
    usuario = jwtDecode(token)

    if (usuario != null && !usuario.trocarSenha && usuario.exp > ((new Date()).getTime() / 1000)) {
      autenticado = true
    } else if (!usuario || (!usuario.trocarSenha && usuario.exp <= ((new Date()).getTime() / 1000))) {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('usuario')
    }
  } else {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('usuario')
  }
} catch (error) {
  console.log('-->', error)
  if (error.name === 'InvalidTokenError') {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('usuario')
  }
}

const app = createApp(App)
app.use(apolloProvider)
app.config.compilerOptions.whitespace = 'preserve'
app.config.globalProperties.$webchat = webchat

const routes = [
  { path: '/noticia', component: Noticia },
  { path: '/nuvemcallback', component: NuvemCallback },
  { path: '/loginunicocallback', component: LoginUnicoCallback },
  { path: '/trocarsenha', component: TrocarSenha },
  { path: '/autenticacaosiop', component: AutenticacaoSIOP },
  { path: '/cadastradores', component: Cadastradores },
  { path: '/cadastradoresSof', component: CadastradoresSof },
  { path: '/:pathMatch(.*)*', component: PaginaInicial }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

app.use(router)

router.beforeEach((to, from, next) => {
  if (to.path !== '/cadastradores' && to.path !== '/cadastradoresSof' && autenticado) {
    window.location.href = '/modulo/main/index.html'
  } else {
    next()
  }
})

app.mount('#app')
