export default Object.freeze({
  urls: {
    auth: {
      login: '/modulo/login/rest/login',
      esqueciSenha: '/modulo/login/rest/recuperarsenha',
      trocarSenha: '/modulo/login/rest/trocarsenha',
      nuvemcallback: '/modulo/login/rest/logincertificadonuvem/callback',
      loginUnicoCallback: '/modulo/login/rest/loginunico/callback',
      loginUnico: '/modulo/login/rest/loginunico',
      loginNeoid: '/modulo/login/rest/logincertificadonuvem?provider=neoid',
      loginCertificado: '/modulo/login/rest/logincertificado'
    },
    noticia: {
      recentes: '/modulo/noticia/externas',
      obter: '/modulo/noticia/obter'
    },
    siop_bi: 'https://siop.gov.br/pentaho/Login?pp=bi',
    manuais: 'https://www1.siop.planejamento.gov.br/siopdoc/doku.php',
    painel_orcamento: 'https://www1.siop.planejamento.gov.br/QvAJAXZfc/opendoc.htm?document=IAS%2FExecucao_Orcamentaria.qvw&host=QVS%40pqlk04&anonymous=true&sheet=SH06',
    orcamento_cidadao: 'https://www1.siop.planejamento.gov.br/QvAJAXZfc/opendoc.htm?document=IAS%2FExecucao_Orcamentaria.qvw&host=QVS%40pqlk04&anonymous=true&sheet=SH15',
    cadastro_acoes: 'https://www1.siop.planejamento.gov.br/acessopublico/?pp=acessopublico&ex=0&fp=inicio',
    dados_abertos: 'https://www1.siop.planejamento.gov.br/siopdoc/doku.php/acesso_publico:dados_abertos',
    siop_legis: 'https://www.siop.planejamento.gov.br/sioplegis/sof',
    acesso_publico: 'https://www1.siop.planejamento.gov.br/acessopublico/?pp=acessopublico',
    siop: '/siop',
    manual_rdf: 'http://vocab.e.gov.br/2013/09/loa',
    solicitar_acesso: 'https://www1.siop.planejamento.gov.br/siopdoc/doku.php/controle_acesso:solicitacao_acesso',
    portal_servicos: 'https://www.siop.gov.br/atendimento/'
  },
  paths: {
    default: '/modulo/main/index.html'
  },
  tela: {
    login: {
      padrao: 1,
      esqueciSenha: 2,
      trocarSenha: 3,
      senhaEnviada: 4,
      trocaSenhaDesativada: 5
    }
  }
})
